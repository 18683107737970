import axios from 'axios';
Vue.prototype.$axios=axios;

//axios.defaults.baseURL ='http://172.168.8.61:8080';  //请求的默认地址
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN; 
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
