/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        {// 重定向
            path: '/',
            redirect: '/index'
        },
        {//首页
            path: '/index',
            name: 'index',
            component: () => import('@/index')
        },
        {//人才招聘详情
            path: '/recruit',
            name: 'recruit',
            component: () => import('@/recruit')
        },
        {//新闻
            path: '/Notice',
            name: 'Notice',
            component: () => import('@/Notice')
        },
        {//新闻详情
            path: '/Notice_detail',
            name: 'Notice_detail',
            component: () => import('@/Notice_detail')
        },
        {//供应链金融
            path: '/Supply_chain',
            name: 'Supply_chain',
            component: () => import('@/Supply_chain')
        },
        {//小微金融
            path: '/xwjr',
            name: 'xwjr',
            component: () => import('@/xwjr')
        },
        {//消费金融
            path: '/xfjr',
            name: 'xfjr',
            component: () => import('@/xfjr')
        },
        {//关于国晟
            path: '/about',
            name: 'about',
            component: () => import('@/about')
        },
        {//公安备案
            path: '/reserved',
            name: 'reserved',
            component: () => import('@/reserved')
        },
        {//注册
            path: '/register',
            name: 'register',
            component: () => import('@/register')
        },
        {//建设中
            path: '/build',
            name: 'build',
            component: () => import('@/build')
        },
        {//登录
            path: '/login',
            name: 'login',
            component: () => import('@/login')
        },
        {//登录
            path: '/apply',
            name: 'apply',
            component: () => import('@/apply')
        },
        {//人才招聘列表
            path: '/personnel',
            name: 'personnel',
            component: () => import('@/personnel')
        },
        {//投诉
            path: '/suggest',
            name: 'suggest',
            component: () => import('@/suggest')
        },
        {//常见问题
            path: '/common_problem',
            name: 'common_problem',
            component: () => import('@/common_problem')
        }
    ],
    scrollBehavior(to, from, saveTop) {
        if (saveTop) {
            return saveTop;
        } else {
            return {x: 0, y: 0}
        }
    }

})
